import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import { isEqual, map } from 'lodash'

import media from '../../../utils/media'
import { APP_STORE_URL, PLAY_STORE_URL } from '../../../config'

const COLUMNS = [{
  title: 'Acheter',
  links: [{
    label: 'Acheter un local professionnel',
    url: '/acheter-local-professionnel'
  }, {
    label: 'Comment et pourquoi se lancer ?',
    url: '/acheter-local-professionnel/comment-et-pourquoi-se-lancer'
  }]
}, {
  title: 'Vendre',
  links: [{
    label: 'Vendre un local professionnel',
    url: '/vendre-local-professionnel'
  }]
}, {
  title: 'Louer',
  links: [{
    label: 'Louer un local professionnel',
    url: '/louer-local-professionnel'
  }]
}, {
  title: 'Nos annonces',
  links: [{
    label: 'Tous nos locaux',
    url: '/nos-annonces'
  }, {
    label: 'Tous nos locaux à acheter',
    url: '/nos-annonces/acheter/?action=acheter'
  }, {
    label: 'Tous nos locaux à louer',
    url: '/nos-annonces/louer/?action=louer'
  }, {
    label: 'Demande d\'accompagnement',
    url: '/nos-annonces/demande-accompagnement'
  }, {
    label: 'Nos tarifs',
    url: '/nos-annonces/tarifs'
  }]
}, {
  title: 'Compte utilisateur',
  links: [{
    label: 'Mon compte',
    url: '/profile/'
  }, {
    label: 'Déposer une annonce',
    url: '/nos-annonces/deposer-une-annonce'
  }, {
    label: 'Nous contacter',
    url: '/nous-contacter'
  }]
}]

const LEGAL_LINKS = [{
  label: 'Mentions légales',
  url: '/mentions-legales'
}, {
  label: 'Conditions Générales d’Utilisation',
  url: '/politique-confidentialite'
}, {
  label: 'Politique de confidentialité',
  url: '/politique-confidentialite'
}]

const ColumnsContainer = styled.div`
  display: flex;
  gap: 24px;

  ${media.lessThan('md')`
    flex-wrap: wrap;
  `}
`

const Column = styled.div`
  flex: 1;

  ${media.lessThan('md')`
    flex: 50%;
  `}
`

const FooterText = styled.p`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  margin: 0;
  color: #fff;
`

const Title = styled(FooterText)`
  margin-bottom: 8px;
  font-weight: bold;
  line-height: 1.25rem;
`

const LinkList = styled.ul`
  list-style-type: none;
  padding: 0;
  
  & > li {
    margin-bottom: 8px;
  }
`

const LinkItem = styled(Link)`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  line-height: normal;
  
  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`

const LinkItemExternal = styled.a`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  line-height: 21px;

  &:hover {
    color: #fff;
    text-decoration: underline;
  }
`

const LegalLink = styled(Link)`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
  padding-left: .5rem;
  padding-right: .5rem;
  line-height: 21px;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &:not(:last-child) {
    border-right : solid 1px #fff;
  }
`

const Separator = styled.hr`
  border: 1px solid #fff;
`

const isBrowser = typeof navigator !== 'undefined'

const ColumnLink = ({ url, type, label, external }) => {
  if (external) {
    return (
      <LinkItemExternal
        rel='noreferrer'
        href={url}
        title={label}>
        {label}
      </LinkItemExternal>
    )
  }

  if (isEqual(type, 'store') && isBrowser) {
    const userAgent = navigator.userAgent

    if (/android/i.test(userAgent)) {
      return (
        <LinkItemExternal
          rel='noreferrer'
          href={PLAY_STORE_URL}
          title={label}>
          {label}
        </LinkItemExternal>
      )
    }

    if ((/ipad|iphone|ipod/gi.test(userAgent) && !window.MSStream) ||
      (/intel mac/gi.test(userAgent) && navigator.maxTouchPoints > 1)) {
      return (
        <LinkItemExternal
          rel='noreferrer'
          href={APP_STORE_URL}
          title={label}>
          {label}
        </LinkItemExternal>
      )
    }
  }

  return (
    <LinkItem
      to={url}
      title={label}>
      {label}
    </LinkItem>
  )
}

ColumnLink.propTypes = {
  url: PropTypes.string.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  external: PropTypes.bool
}

ColumnLink.defaultProps = {
  type: '',
  external: false
}

const Columns = () => map(COLUMNS, ({ title, links }) => (
  <Column key={title}>
    <Title>{title}</Title>
    <LinkList>
      {map(links, ({ url, type, label, external }) => (
        <li key={`${label}-${url}`}>
          <ColumnLink
            url={url}
            type={type}
            label={label}
            external={external} />
        </li>
      ))}
    </LinkList>
  </Column>
))

const LegalLinks = () => map(LEGAL_LINKS, ({ label, url }) => (
  <LegalLink
    to={url}
    rel='noreferrer'
    key={`${label}-${url}`}
    title={label}
    target='_blank'>
    {label}
  </LegalLink>
))

const FooterLinks = () => (
  <>
    <ColumnsContainer>
      <Columns />
    </ColumnsContainer>
    <Separator />
  </>
)

export default FooterLinks
