import React from 'react'
import styled from 'styled-components'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const NotFoundImage = ({ ...props }) => {
  const { placeholderImage } = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "warning.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }`)

  const image = getImage(placeholderImage)

  return (
    <GatsbyImage
      alt='not-found-image'
      image={image}
      {...props} />
  )
}

const StyledImage = styled(NotFoundImage)`
  width: 31px;
  height: auto;
`

export default StyledImage
