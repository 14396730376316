import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { useStaticQuery, graphql } from 'gatsby'

const StyledImg = styled(GatsbyImage)`
  width: 163px;
  height: auto;
`

const FooterLogo = (props) => {
  const data = useStaticQuery(graphql`{
    placeholderImage: file(relativePath: {eq: "logos/icon-white-big.png"}) {
      childImageSharp {
        gatsbyImageData(
          formats: [AUTO, WEBP, AVIF]
          layout: FULL_WIDTH
        )
      }
    }
  }
  `)

  return (
    <StyledImg
      alt='footer-logo'
      image={data.placeholderImage.childImageSharp.gatsbyImageData}
      {...props} />
  )
}

export default FooterLogo
