import React from 'react'
import styled from 'styled-components'
import { get, map } from 'lodash'
import { Link } from 'gatsby'

import media from '../../../utils/media'
import Container from '../../atoms/Container'
import FooterLogo from '../../atoms/FooterLogo'
import FooterLinks from '../../molecules/FooterLinks'
// import SocialButtons from '../../atoms/SocialButtons'

const FooterContainer = styled(Container)`
  padding: 40px 0;
  background-color: ${({ theme }) => get(theme, 'blue', '#fff')};
`

const FooterBottom = styled.div`
  display: flex;
  gap: 24px;
  margin : 0 40px;
  flex-direction: column;

  ${media.greaterThan('md')`
    gap: 0px;
  `}

  ${media.greaterThan('xxl')`
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 48px;
    padding-bottom: 72px;
  `}
`
const FirstBlockFooter = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;

  ${media.greaterThan('md')`
    flex-direction: row;
    align-items: center;
  `}

  ${media.greaterThan('md')`
    gap: 40px;
  `}
`

const LogoCol = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${media.lessThan('md')`
    gap: 16px;
    align-items: center;
  `}
`

const LinksCol = styled.div`
  flex: 2;
`

// const ButtonsCol = styled.div`
//   flex: 1;
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   gap: 16px;

//   ${media.greaterThan('md')`
//     flex-direction: row;
//     margin: auto;
//   `}
// `
// const TrustPilotWidget = styled.div`
//   margin-left: 1%;
// `

const LastFooterBlock = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  justify-content: space-between;

  ${media.greaterThan('md')`
    flex-direction: row;
    flex-direction: column;
    gap: 8px;
    margin-left: 200px;
  `}

  ${media.greaterThan('xxl')`
    margin: 0;
    gap: 24px;
  `}
`

const Legal = styled.div`
  font-family: 'Source Sans Pro';
  color: #fff;
  font-size: 14px;
  text-align: center;
  font-weight: 600;
`

const LegalLink = styled(Link)`
  font-family: 'Source Sans Pro';
  font-size: 14px;
  font-weight: 600;
  color: #fff;
  text-decoration: underline;
  padding-left: .5rem;
  padding-right: .5rem;
  line-height: 21px;

  &:hover {
    color: #fff;
    text-decoration: none;
  }

  &:not(:last-child) {
    border-right: solid 1px #fff;
  }
`

const LinksContainer = styled.div`
  text-align: center;
  margin-top: .5rem;
`

const LEGAL_LINKS = [{
  label: 'Mentions légales',
  url: '/mentions-legales'
}, {
  label: 'Conditions Générales d’Utilisation',
  url: '/conditions-generales-utilisation'
}, {
  label: 'Conditions Générales de vente',
  url: '/conditions-generales-vente'
}]

const LegalLinks = () => map(LEGAL_LINKS, ({ label, url }) => (
  <LegalLink
    to={url}
    rel='noreferrer'
    key={`${label}-${url}`}
    title={label}
    target='_blank'>
    {label}
  </LegalLink>
))

const Footer = () => (
  <>
    <FooterContainer fluid>
      <FooterBottom>
        <FirstBlockFooter>
          <LogoCol md={3}>
            <FooterLogo />
            {/* <Script type='text/javascript' src='//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js' async />
            <TrustPilotWidget className='trustpilot-widget' data-locale='fr-FR' data-template-id='53aa8807dec7e10d38f59f32' data-businessunit-id='62287c92fbeb2611daaf6302' data-style-height='100px' data-style-width='180px' data-theme='dark' data-font-family='Source Sans Pro'>
              <a href='https://fr.trustpilot.com/review/folhomee.fr' target='_blank' rel="noopener noreferrer" style={{ display: 'none' }}>Trustpilot</a>
            </TrustPilotWidget> */}
          </LogoCol>
          <LinksCol md={6}>
            <FooterLinks />
          </LinksCol>
        </FirstBlockFooter>
        <LastFooterBlock>
          <Legal>contact@trouvermonlocalpro.fr</Legal>
          <Legal>
            {/* Folhomee SAS 2017-{new Date().getFullYear()} &copy; Tous droits réservés */}
            TrouverMonLocalPro &copy; Tous droits réservés
            <LinksContainer>
              <LegalLinks />
            </LinksContainer>
          </Legal>
          {/* <ButtonsCol>
            <SocialButtons />
          </ButtonsCol> */}
        </LastFooterBlock>
      </FooterBottom>
    </FooterContainer>
  </>
)

export default Footer
