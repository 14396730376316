export const CITY_URL = 'https://api-adresse.data.gouv.fr/search/'

export const LINKS_NAVBAR = process.env.GATSBY_LINKS_NAVBAR || false

export const API_SALES_URL = process.env.GATSBY_API_SALES_URL || 'http://localhost:3670'

export const GOOGLE_MAP_API_KEY = process.env.GATSBY_GOOGLE_MAP_API_KEY || 'AIzaSyBQvsE56gDNCZZ7gw_RT_D3RY1R0q3fei4'

export const PHONE_REGEXP = /^((0[0-9]{9})|(\+(33|262|508|590|594|596)[0-9]{9}))$/

export const APP_STORE_URL = 'https://apps.apple.com/gb/app/folhomee/id1497318064'

export const PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=fr.folhomee.webapp'

export const PAGE_SIZE = 12

export const API_STRIPE = process.env.API_STRIPE || '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'
